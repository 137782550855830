import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const isDevelopment = process.env.NODE_ENV === 'development';

export default i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'pt-BR',
    fallbackLng: 'pt-BR',
    debug: isDevelopment,
    react: {
      useSuspense: false,
      wait: true,
    },
  });
